import { colors } from '../../../styles/atoms/colors';

export const tagColor = {
  news: {
    bg: colors.aqua[100],
    text: colors.aqua[700],
  },
  pressreleases: {
    bg: colors.purple[50],
    text: colors.purple[600],
  }
};